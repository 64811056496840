import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG, KOHORT } from './core/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event, RouterEvent } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoadingService } from './core/services/loading.service';
import { Subscription } from 'rxjs';
import { UpdateService } from './core/services/update.service';
import pkg from '../../package.json';
import { RouterService } from './core/services/router.service';
import { SentryService } from './core/sentry/sentry';
import { BreadcrumbService } from './core/breadcrumbs/breadcrumbs.service';
import { AppConfig } from "./core/config/models/config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('500ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('500ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(translate: TranslateService,
              // This is just injected to instantiate
              private updateService: UpdateService,
              private router: Router,
              private sentry: SentryService,
              @Inject(APP_CONFIG) private appConfig: AppConfig,
              private loadingService: LoadingService,
              private routerService: RouterService,
              private breadcrumbService: BreadcrumbService,
  ) {
    translate.setDefaultLang('en');

    this.subscription = this.router.events.subscribe((event: Event | RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.start();
          this.initAppLoader();
          break;
        }
        case event instanceof NavigationEnd: {
          this.routerService.setRoute(this.router.routerState.snapshot);
          this.loadingService.complete();
          this.destroyAppLoader();
          /**
           * This has to be added here because this component gets all the
           * router events whereas the breadcrumb service only gets one of type "Scroll"
           */
          this.breadcrumbService.build();
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.complete();
          this.destroyAppLoader();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  get environment() {
    return this.appConfig;
  }

  public version = pkg.version;
  public firstLoad$ = this.loadingService.loadingState;;
  private subscription: Subscription;

  public ngOnInit(): void {
    this.sentry.boot();
    console.log(KOHORT, this.version);
  }

  initAppLoader() {
    if (this.firstLoad$) {
      this.loadingService.show();
    }
  }

  destroyAppLoader() {
    if (this.firstLoad$) {
      this.firstLoad$ = null;
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
