import { Component, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";

@Component({
  selector: 'app-user-without-org',
  templateUrl: './user-without-org.component.html',
  styleUrls: ['./user-without-org.component.scss'],
})
export class UserWithoutOrgComponent implements OnInit {
  constructor(private auth0Service: AuthService) {}

  ngOnInit(): void {
    // Logout user after 10 seconds
    setTimeout(() => {
      this.logout();
    }, 10000);
  }

  logout(): void {
    this.auth0Service.logout();
  }
}
