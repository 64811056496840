import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { lastValueFrom, merge, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SIDE_BAR_LOCAL_STORAGE_KEY } from 'src/app/core/app.constants';
import { Auth0UserTokenKeys } from 'src/app/core/config/models/config';
import { HelpersService } from 'src/app/core/services/helpers.service';

@Component({
  template: `
    <app-loading></app-loading>
  `,
  styles: [`:host {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }`]
})
export class CallbackComponent implements OnInit, OnDestroy {

  private authenticationSubscription$: Subscription;

  constructor(
    private auth0Service: AuthService,
    private helpersService: HelpersService,
    private router: Router,
    @Inject(DOCUMENT) public document: Document
  ) {
  }

  ngOnInit() {
    this.helpersService.deleteFromLocalStorage(SIDE_BAR_LOCAL_STORAGE_KEY);
    this.authenticationSubscription$ = merge(this.auth0Service.idTokenClaims$, this.auth0Service.error$).pipe(
      take(1),
      catchError((e) => {
        return throwError(e)
      })
    ).subscribe((token) => {
      if (this.isIdToken(token)) {
        const organization = !token?.org_id ? token?.[Auth0UserTokenKeys.METADATA]?.org_id : token?.org_id;
        if (Boolean(organization?.length)) {
          this.setUserOrganization(organization);
          return;
        }
      }
      this.router.navigate(['/user-without-org']);
    })
  }

  isIdToken(token: any): token is IdToken {
    return 'sub' in token;
  }

  ngOnDestroy(): void {
    this.authenticationSubscription$?.unsubscribe();
  }

  async setUserOrganization(organization: string) {
    await lastValueFrom(this.auth0Service.getAccessTokenSilently({authorizationParams: {organization}, cacheMode: 'off'}));
  }
}
