import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';
import {SentryService} from './sentry';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    SentryService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class SentryModule {
}
