import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from "../app.constants";
import { AppConfig } from "../config/models/config";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { AppState, trackPage } from 'src/app/store';
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public title: Title,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) { }

  setTitle(title: string) {
    const pageTitle = this.translateService.instant(`${title}`);
    this.title.setTitle(`${this.config?.pageTitle} - ${pageTitle}`);
    this.store.dispatch(trackPage({pageTitle}));
  }
}
