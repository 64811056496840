import { Injectable } from "@angular/core";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";
import { AppState, setPageHeaderContent } from "../store";
import { Store } from "@ngrx/store";
import { TitleService } from "../core/services/title.service";

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {

  constructor(
    private readonly titleService: TitleService,
    private store: Store<AppState>
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const pageTitleKey = this.buildTitle(routerState);
    if (pageTitleKey !== undefined && !pageTitleKey.includes("customDashboards")) {
      const pageTitle = this.getReportTitle(pageTitleKey, routerState.url);
      this.store.dispatch(setPageHeaderContent({pageTitle}));
      this.titleService.setTitle(`${pageTitle}.title`);
    }
  }

  getReportTitle(pageTitleKey: string, url: string): string {
    // get the report name from the url and append it to the page title key. Make sure to remove any query params
    const urlSegments = url.split('/');
    const reportName = urlSegments[urlSegments.indexOf('reports') + 1]?.split('?')[0] || 'landing';
    return pageTitleKey.includes('reports') ? `${pageTitleKey}.${reportName}` : pageTitleKey;
  }
}
