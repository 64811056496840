import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  template: `
    <app-loading></app-loading>
  `,
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    public auth0Service: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    const {organization, invitation} = this.activatedRoute.snapshot.queryParams;
    if (organization && invitation) {
      this.loginWithOrganizationInvitation(organization, invitation);
    } else {
      this.router.navigate(['']);
    }
  }

  private loginWithOrganizationInvitation(organization: string, invitation?: string): void {
    this.auth0Service.loginWithRedirect({authorizationParams: {organization, invitation}});
  }

}
