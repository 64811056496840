import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { State } from 'src/app/views/user-management/redux/reducers';
import { Store } from '@ngrx/store';
import { loadApp } from 'src/app/views/user-management/redux/actions';
import { getAppInitData } from 'src/app/views/user-management/redux/selectors';
import { UserRole } from 'src/app/views/user-management/model/user-management.model';
import { Auth0UserTokenKeys } from '../config/models/config';

export interface UserResolveInterface {
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class UserResolve  {
  constructor(
    private auth0Service: AuthService,
    private accountService: AccountService,
    private store: Store<State>
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth0Service.user$.pipe(
      map((user) => {
        const orgId = user?.org_id ? user?.org_id : user?.[Auth0UserTokenKeys.METADATA]?.org_id
        const roles = user[Auth0UserTokenKeys.ROLES];
        const isSuperAdmin = roles.includes(UserRole.SUPER_ADMIN);
        const isNormalUser = !(roles.includes(UserRole.ADMIN) || roles.includes(UserRole.SUPER_ADMIN));
        this.store.dispatch(loadApp({userId: user.sub, orgId, isNormalUser, isSuperAdmin}));
        return { ...user, org_id: orgId  };
      }),
      switchMap(authUser => this.store.select(getAppInitData).pipe(
        filter(data => !!data.user && !!data.currentOrgName),
        map(({user, currentOrgName}) => {
          this.accountService.authenticate({ ...authUser, org_name: currentOrgName, created_at: user.createdAt });
          this.accountService.setInternalRole(user.isInternal);
          return user;
        })
      )),
      take(1),
      catchError((e) => {
        return of(e);
      }));

    }
}
