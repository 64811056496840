import {ApplicationRef, ErrorHandler, Injectable, OnDestroy} from '@angular/core';

import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {AlertService} from './alert.service';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subject} from 'rxjs';
import {filter, first, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ConfirmationService} from './confirmation.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService implements OnDestroy {
  private onDestroy = new Subject<void>();
  private checkInterval = (5 * 60) * 1000;

  constructor(private swUpdate: SwUpdate,
              errorHandler: ErrorHandler,
              private alert: AlertService,
              private translateService: TranslateService,
              private appRef: ApplicationRef,
              private confirmationService: ConfirmationService,
  ) {

    appRef.isStable.pipe(
        first(isStable => isStable === true),
        takeUntil(this.onDestroy),
      ).subscribe(async() => await this.swUpdate.checkForUpdate());

    this.swUpdate.versionUpdates.pipe(
      filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
      switchMap(() => combineLatest([
        this.translateService.get('app.updateTitle'),
        this.translateService.get('app.update'),
        this.translateService.get('app.action'),
        this.translateService.get('app.updateInAction'),
        ])),
      takeUntil(this.onDestroy),
    ).subscribe(([title, message, action, updateInAction]) => {
      this.confirmationService.updateWebapp(
        () => {
          this.alert.message(updateInAction, 'info');
          this.swUpdate.activateUpdate().then(() => 
            document.location.reload()
          ).catch(error => 
            console.error(`Failed to apply updates:, ${error}`)
          );
        },
        title,
        message,
        action,
      );
    });

    this.swUpdate.unrecoverable
      .pipe(
        tap(evt => {
          const errorMsg = `Unrecoverable state: ${evt.reason}`;
          errorHandler.handleError(errorMsg);
        }),
        takeUntil(this.onDestroy),
      )
      .subscribe(() => window.location.reload());
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
