import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input({
    transform: (value: string) => {
      return value || 'unknown';
    }
  }) code: string;

  constructor(
    private auth0Service: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) {
  }

  async logout(): Promise<void> {
    await firstValueFrom(this.auth0Service.logout({logoutParams: {returnTo: this.document.location.origin}}));
  }

  async retry(): Promise<void> {
    await firstValueFrom(this.auth0Service.loginWithRedirect());
  }

}
