import {inject, Injectable} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import {Observable, catchError} from 'rxjs';
import pkg from '../../../package.json';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../core/services/alert.service';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  version = pkg.version;

  private messageService = inject(AlertService);
  private translateService = inject(TranslateService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({headers: req.headers.set('Control-Client', this.version)});
    return next.handle(newReq).pipe(
      catchError((error) => {
        console.error('[App NetWork Interceptor Error]: ', error);
        if (error instanceof HttpErrorResponse) {
          if(error.status >= 500 || error.status === 0) { // check for server errors or connection issues
            this.messageService.error(
              `${this.translateService.instant('error.code', {code: error.status})} - ${this.translateService.instant('error.internal')}`
            )
          }
        }
        throw error;
      })
    );
  }
}


