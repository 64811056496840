import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../material.module';
import { ActivityHeaderComponent } from './activity-header/activity-header.component';
import { ActivityContentComponent } from './activity-content/activity-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationComponent } from './notifications/notification/notification.component';
import { ScenarioJobComponent } from './notifications/scenario-job.component';
import { SharedModule } from '../shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ActivityHeaderComponent,
    ActivityContentComponent,
    NotificationComponent,
    ScenarioJobComponent
  ],
  exports: [
    ActivityHeaderComponent,
    ActivityContentComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    SharedModule,
    TranslateModule.forChild()
  ]
})
export class ActivityModule {
}
