import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { State } from 'src/app/views/user-management/redux/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOrganisation } from '../model/user-management.model';
import { selectAuthUserOrgById} from '../redux/selectors';

@Injectable({
  providedIn: 'root'
})
export class UserOrganisationResolver  {

  constructor(private store: Store<State>, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IOrganisation> {
    const urlOrgId = state.url.split('/').find((path: string) => path.includes('org'));
    const orgId: string = urlOrgId ? urlOrgId : route.parent.data.user?.currentOrgId;
    return this.store.select(selectAuthUserOrgById(orgId)).pipe(
      filter(org => !!org),
      take(1)
    );
  }
}
